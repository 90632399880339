import ApiBase from 'ApiRest/ApiBase'

export const ApiFetchSchoolDocumentsTemplateTypes = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    const url = `/option/school-document-template-types`

    return this._GET(url)
  }
})()
