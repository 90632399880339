export default {
  setSchoolId(state, payload) {
    state.schoolId = payload
  },

  setTemplate(state, { key, payload }) {
    state.templates[key] = {
      ...payload,
      key,
    }
  },

  unsetTemplate(state, key) {
    state.templates[key] = null
  },

  setSchoolDocuments(state, payload) {
    state.schoolDocuments = payload
  },
}
