export default () => ({
  templates: {
    offer: null,
    acceptanceAct: null,
  },

  schoolId: null,

  schoolDocuments: [],
})
